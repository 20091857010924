
import Vue, { PropType } from "vue"
import { AnalyticsEvent, Comment } from "@evercam/shared/types"

export default Vue.extend({
  name: "TheCommentsList",
  props: {
    comments: {
      type: Array as PropType<Comment[]>,
      default: () => [] as Array<Comment>,
    },
    selectedComment: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
  },
  data() {
    return {
      selectedCommentId: null,
    }
  },
  watch: {
    selectedComment: {
      handler(comment) {
        if (comment) {
          this.selectedCommentId = comment.id
          this.scrollToSelectedComment(comment.id)
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatedTimestamp(timestamp) {
      return this.$moment
        .tz(timestamp, this.timezone)
        .format("MMM DD, YYYY [at] hh:mm A")
    },
    async openDeleteDialog(comment) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_comment"),
          message: this.$t("content.comments.delete"),
        })
      ) {
        this.$emit("delete-comment", comment)
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.commentsRemove,
        })
      } else {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.commentsCancelRemove,
        })
      }
    },
    scrollToSelectedComment(commentId) {
      const el = document.querySelector(`.selected-comment-${commentId}`)

      if (el) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    },
  },
})
